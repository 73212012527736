<template>
  <div class='financial'>

    <div class="form">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px" style="width: 100%;">
        <div class="title">收款账户信息</div>
        <el-row :gutter="40">
          <el-col :span="12" class="col">
            <el-form-item label="开户行" prop="openBank">
              <el-input v-model="form.openBank" placeholder="请输入开户行"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="accountNum">
              <el-input v-model="form.accountNum" placeholder="请输入账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户名" prop="accountName">
              <el-input v-model="form.accountName" placeholder="请输入户名"></el-input>
            </el-form-item>
            <el-form-item label="联行号" prop="interbankNum">
              <el-input v-model="form.interbankNum" placeholder="请输入联行号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="submit">
          <el-button type="primary" @click="handleSumbit('ruleForm')">确定</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// 表单初始化
function createdForm() {
  return {
    openBank: "", // 开户行
    accountNum: "", // 账号
    accountName: "", // 户名
    interbankNum: "", // 联行号
  }
}
export default {
  data() {
    return {
      form: createdForm(),
      rules: {
        accountNum: [{ required: true, message: "请输入账号", trigger: "blur" }, { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' }],
        interbankNum: [{ required: true, message: "请输入联行号", trigger: "blur" }, { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' }],
        openBank: [{ required: true, message: "请输入开户行", trigger: "blur" }],
        accountName: [{ required: true, message: "请输入户名", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getSelectFinanceBankDetail()
  },
  methods: {
    getSelectFinanceBankDetail() {
      this.$axios.get(this.$api.getSelectFinanceBankDetail, {
        params: {
          bizType: 3
        }
      }).then(res => {
        if (res.data.code == 100) {
          this.$set(this, 'form', res.data.result || createdForm());
        } else {
          this.$message({ showClose: true, message: res.data.desc, type: "error", });
        }
      })
    },
    // 确定
    handleSumbit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.updateFinanceBank, {
            accountNum: this.form.accountNum, // 账号
            accountName: this.form.accountName, // 户名
            openBank: this.form.openBank, // 开户行
            interbankNum: this.form.interbankNum, // 联行号
            id: this.form.id,
            bizType: 3
          }).then(res => {
            if (res.data.code == 100) {
              this.$message.success('保存成功！');
              this.getSelectFinanceBankDetail()
            } else {
              this.$message({ showClose: true, message: res.data.desc, type: "error", });
            }
          })
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.financial {
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
  }

  .form {
    display: flex;
    width: 900px;
    margin: 0 auto;

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    .el-input {
      width: 250px;
    }

    .submit {
      text-align: center;
      padding-top: 300px;

      .el-button {
        width: 100px;
      }
    }
  }
}
</style>
